// Import custom font from Google
@import url('https://fonts.googleapis.com/css?family=Raleway:200,400,700')

// Color Variables
$dark-blue: #252934
$bright-blue: #00a1c9
$pink: #E31B6D
$white: white
$light-grey: #f5f5f5

// Grid Screen Sizes
$screen-xl: 1200px
$screen-lg: 992px
$screen-md: 768px
$screen-sm: 576px

// Set brand colors
$primary: $bright-blue
$secondary: $pink
$background: $dark-blue
$secondary-background: $light-grey

// Override fonts
$font-family-sans-serif: 'Raleway', sans-serif
$font-family-serif: Georgia, 'Times New Roman', Times, serif
$font-family-monospace: Menlo, Monaco, Consolas, 'Liberation Mono', 'Courier New', monospace
$font-bold: 700

// Fixed Sizes
$navbar-height: 40px


a
  color: $bright-blue !important
  &:hover
    color: $pink !important
    opacity: .8 !important


// Button
button:focus, input:focus, textarea:focus
  outline: unset
.outline-button
  background: transparent
  border: 2px solid #fff
  font-size: 13pt
  font: $white

// Header
.nav
  height: $navbar-height
  padding: 20px
  background-color: $background
  text-align: center
  .nav-link-container
    margin-left: auto
    margin-right: auto
    left: 0
    right: 0
    a
      padding-left: 35px
      padding-right: 35px
      text-decoration: underline !important

// Footer
#footer
  height: 350px
  background: $background
  text-align: center
  .scroll-top-button
    background: $pink
    color: $white
    padding: 20px 10px 10px 10px
    font-size: 50px
    font-weight: 700
    border-radius: 5px
    img
      height: 50px
// Home
.social-icon-group
  margin-top: 30px
  .social-icon-item
    margin: 10px 40px 0px 40px
    width: 100px
.section-container
  border-top: 20px solid $bright-blue
  padding-top: 50px
  text-align: center
  .section-header
    font-size: 40px
    font-weight: $font-bold
    .section-header-underline
      width: 70px
      height: 2px
      margin: 5px auto 100px auto
.section-background-primary
  background-color: $background
  color: white
  .section-header-underline
    background-color: white
.section-background-secondary
  background-color: $secondary-background
  .section-header-underline
    background-color: black

// Particles
#tsparticles
  position: fixed
  top: $navbar-height
  width: 100%
  background:#252934
  position: absolute
  z-index: 0
  @supports (-webkit-overflow-scrolling: touch)
    height: 600px
  @supports not (-webkit-overflow-scrolling: touch)
    height: 100%

// Introduction
#introduction
  @supports (-webkit-overflow-scrolling: touch)
    height: 600px
  @supports not (-webkit-overflow-scrolling: touch)
    height: calc(100vh)
.overlay
  margin-left: auto
  margin-right: auto
  position: absolute
  text-align: center
  left: 0
  right: 0
  color: $white
  margin-top: 250px
  .title-subtext
    font-size: 20px
  .title-text
    z-index: 1
    font-size: 50px
    margin: 0px 10px 0px 10px
  .name-highlight
    display: -webkit-inline-box
    color: $pink
    margin-bottom: 20px

// #ABOUT
$skills-item-size: 100px
.about-container
  padding-bottom: 100px
.about-grid-container
  max-width: 90%
  .highlights-row
    margin-bottom: 50px
    .highlight
      margin-bottom: 50px
    .highlight-bubble
      background: $bright-blue
      height: 150px
      width: 150px
      border-radius: 50%
      margin: 0px auto 50px auto
  .headshot
    height: 300px
    border-radius: 50%
  .headshot-title, .highlight-title
    font-size: 24px
    font-weight: 700
  .headshot-description, .highlight-description
    font-size: 16px
.skills
  padding-top: 20px
  .row
    margin-top: 20px
  .skill-group
    display: contents
  .skills-title
    font-size: 2.4rem
    font-weight: 700
    line-height: $skills-item-size
    text-align: left
  .skills-item
      width: $skills-item-size
      height: $skills-item-size
      line-height: $skills-item-size
      border-radius: 50%
      color: $white
      text-align: center
      background: $pink
      margin-left: 10px
      margin-top: 10px
// Resume
.resume-container
  padding-bottom: 50px
  .resume-section
    max-width: 850px
    max-height: 1000px
    margin: 0px auto 50px auto
    @media (max-width: $screen-sm)
      width: 300px
      height: 350px
    @media (min-width: $screen-sm)
      width: 300px
      height: 350px
    @media (min-width: $screen-md)
      width: 650px
      height: 800px
    @media (min-width: $screen-lg)
      width: 850px
      height: 1000px
    .resume-download
      background-color: $pink
      width: 100%
      color: $white !important


.contact-container
  height: 600px
  .contact-form
    width: 350px
    margin: 0px auto 100px auto
    text-align: left
    .contact-form-error
      color: $pink
    .contact-form-success
      color: $bright-blue
    .contact-form-input
      width: 100%
      margin-bottom: 20px
      border: 2px solid $bright-blue
      border-radius: 4px
      padding-left: 5px
      height: 40px
    .contact-form-input-textarea
      height: 100px
      margin-bottom: 10px
    .contact-form-input::-webkit-input-placeholder
    .submit-button
      float: right
      background: $background
      color: white
      border: 2px solid $bright-blue
      padding: 5px 10px 5px 10px
    .submit-button::hover
      background: $bright-blue
